import { Components } from '@mui/material';
import { palette } from '../palette';

const MuiTabsOverrides: Components = {
  MuiTabs: {
    styleOverrides: {
      root: {
        display: 'flex',
        color: palette.contentPrimary.primary,
        overflow: 'visible',
      },
      indicator: {
        backgroundColor: palette.accent.main,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        'backgroundColor': 'transparent',
        'color': palette.contentPrimary.primary,
        'textTransform': 'none',
        'fontSize': 16,
        'borderBottom': `2px solid ${palette.bgPrimary.tertiary}`,
        '&.Mui-selected': {
          color: palette.accent.main,
        },
        '&.Mui-disabled': {
          color: palette.contentPrimary.disabled,
        },
      },
      selected: {},
      disabled: {},
    },
  },
};

export default MuiTabsOverrides;
