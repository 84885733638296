import { EconomyOneTimeExpenseType, EconomyOneTimeIncomeType } from './economy';
import { PremiumCurrencyExpenseType, PremiumCurrencyIncomeType } from './premium-currency';

export enum EconomyEventType {
  ONE_TIME_EXPENSE = 'oneTimeExpense',
  ONE_TIME_INCOME = 'oneTimeIncome',
  PREMIUM_EXPENSE = 'premiumExpense',
  PREMIUM_INCOME = 'premiumIncome',
  PASSIVE_CHANGE = 'passiveChange',
}

export interface EconomyEvent {
  userId: string;
  date: string;
  type: EconomyEventType;
}

// ONE TIME EVENTS

export interface EconomyOneTimeExpenseEvent extends EconomyEvent {
  type: EconomyEventType.ONE_TIME_EXPENSE;
  amount: number;
  category: EconomyOneTimeExpenseType;
}

export interface EconomyOneTimeIncomeEvent extends EconomyEvent {
  type: EconomyEventType.ONE_TIME_INCOME;
  amount: number;
  category: EconomyOneTimeIncomeType;
}

// PASSIVE CHANGED EVENTS

export enum EconomyPassiveChangeReason {
  ACTIVE_ATHLETES_CHANGE = 'activeAthletesChange',
  ACTIVE_STAFFS_CHANGE = 'activeStaffsChange',
  ROOM_LEVEL_CHANGE = 'roomLevelChange',
  SEASON_PROGRESSION = 'seasonProgression',
  DAY_HANDLING = 'dayHandling',
}

export interface EconomyPassiveChangeEvent extends EconomyEvent {
  type: EconomyEventType.PASSIVE_CHANGE;
  reasons: EconomyPassiveChangeReason[];
}

// PREMIUM CURRENCY EVENTS
export interface EconomyPremiumExpenseEvent extends EconomyEvent {
  type: EconomyEventType.PREMIUM_EXPENSE;
  amount: number;
  transactionId?: string;
  category: PremiumCurrencyExpenseType;
}

export interface EconomyPremiumIncomeEvent extends EconomyEvent {
  type: EconomyEventType.PREMIUM_INCOME;
  amount: number;
  transactionId: string;
  category: PremiumCurrencyIncomeType;
}
