import {
  createUserTeam,
  fireUserTeamAthlete,
  hireUserTeamAthlete,
  queryUserTeam,
  updateUserTeam,
} from '@/lib/aws/user/user.api';
import { AthleteCard, Team, TeamLogo } from '@cstactics/types';
import { useGameAssetsStore } from '../game-assets.store';
import { RosterAthletes } from '@/pages/team';


export async function createTeam(name: string, tag: string, logo: TeamLogo): Promise<void> {
  await createUserTeam({ name, tag, logo });
  const team = await queryUserTeam();
  console.debug(`[team.actions] createTeam:: ${team!.name}`);
 
  useGameAssetsStore.setState({ team });
}

export async function hireAthlete(athleteCardId: string): Promise<void> {
  const teamId = useGameAssetsStore.getState().team?.uuid;
  if (!teamId) throw new Error('No Team set to hire athlete');

  const { team, athleteCard } = await hireUserTeamAthlete(teamId, athleteCardId);

  useGameAssetsStore.setState({ team });
  useGameAssetsStore.setState(({ athleteCards }) => {
    if (!athleteCards) return {};

    const athleteIndex = athleteCards.findIndex((ac) => ac.uuid === athleteCard.uuid);
    if (athleteIndex === -1) return {};

    athleteCards[athleteIndex] = athleteCard;
    return { athleteCards: [...athleteCards] };
  });
}

export async function fireAthlete(athleteCardId: string): Promise<void> {
  const teamId = useGameAssetsStore.getState().team?.uuid;
  if (!teamId) throw new Error('No Team set to fire athlete');

  const { team, athleteCard } = await fireUserTeamAthlete(teamId, athleteCardId);

  useGameAssetsStore.setState({ team });
  useGameAssetsStore.setState(({ athleteCards }) => {
    if (!athleteCards) return {};

    const athleteIndex = athleteCards.findIndex((ac) => ac.uuid === athleteCard.uuid);
    if (athleteIndex === -1) return {};

    athleteCards[athleteIndex] = athleteCard;
    return { athleteCards: [...athleteCards] };
  });
}

export async function updateTeam(updates: Partial<Team>): Promise<void> {
  const teamId = useGameAssetsStore.getState().team?.uuid;
  if (!teamId) throw new Error('No Team set to update');

  await updateUserTeam(teamId, updates);

  // can be removed after https://skillshot-fdi-mb.atlassian.net/browse/CM-694 is merged
  const team = await queryUserTeam();

  useGameAssetsStore.setState({ team });
}

export function setBenchAthletes(benchAthletes: AthleteCard[]) {
  useGameAssetsStore.setState({ benchAthletes });
}

export function setRosterAthletes(rosterAthletes: RosterAthletes) {
  useGameAssetsStore.setState({ rosterAthletes });
}

export function isAthleteInTeam(athleteCardId: string): boolean {
  const team = useGameAssetsStore.getState().team;
  if (!team) return false;

  return team.bench.includes(athleteCardId) || Object.values(team.roster).includes(athleteCardId);
}

export function isAthleteInTeamRoster(athleteCardId: string): boolean {
  const team = useGameAssetsStore.getState().team;
  if (!team) return false;
  return Object.values(team.roster).includes(athleteCardId);
}
