import { BombActionCategory, isBombActionCategory } from '../../announcer';
import { TimeLogBase, LogType, isTimeLogBase, TimeLog } from './log';

export interface BombActionLog extends TimeLogBase {
  logType: LogType.BOMB_ACTION;
  actionType: BombActionCategory;
  actionValue: number;
  /** either holds athlete uuid of terrorist carrying the bomb or counterTerrorist trying to defuse bomb (pre / post bomb) */
  bombActor: string;
  bombLocation: string;
  teamId: string;
  success?: boolean;
}
export function isBombActionLog(log: TimeLog): log is BombActionLog {
  return (
    log.logType === LogType.BOMB_ACTION &&
    isBombActionCategory(log.actionType) &&
    typeof log.actionValue === 'number' &&
    typeof log.bombActor === 'string' &&
    typeof log.bombLocation === 'string'
  );
}

export enum BombActionType {
  'PLACE' = 'place',
  'PICK' = 'pick',
  'DEFUSE' = 'defuse',
}

export const bombActionTypeValues = Object.values(BombActionType);
export function isBombActionType(baType: any): baType is BombActionType {
  return bombActionTypeValues.includes(baType);
}
