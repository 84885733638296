import { GameLogEventBase } from './events';

export interface GameLogAthleteEventBase extends GameLogEventBase {
  teamId: string;
  athleteId: string;
  actionType: GameLogAthleteEventType;
  gameTime: number;
}

export interface GameLogAthleteEventBomb extends GameLogAthleteEventBase {
  actionType: GameLogAthleteEventType.BOMB;
  category: Omit<BombActionCategory, 'bombPick' | 'bombCarrierDead'>;
  success: boolean;
  location: string;
}
export function isGameLogAthleteEventBomb(action: any): action is GameLogAthleteEventBomb {
  return action.actionType === GameLogAthleteEventType.BOMB && isBombActionCategory(action.category);
}

export interface GameLogAthleteEventKill extends GameLogAthleteEventBase {
  actionType: GameLogAthleteEventType.KILL;
  /** who got killed by athleteId */
  opponentId: string;
  location: string;
}
export function isGameLogAthleteEventKill(action: any): action is GameLogAthleteEventKill {
  return action.actionType === GameLogAthleteEventType.KILL;
}

// we currently only show kills in the gameLog
export type GameLogAthleteEvent = GameLogAthleteEventKill | GameLogAthleteEventBomb; // | AthleteActionApproach | AthleteActionMove | AthleteActionBomb

// we currently only show kills in gameLog
export enum GameLogAthleteEventType {
  BOMB = 'bomb',
  KILL = 'kill',
}
export const gameLogAthleteEventTypeValues = Object.values(GameLogAthleteEventType);
export function isAthleteGameLogEventType(type: any): type is GameLogAthleteEventType {
  return gameLogAthleteEventTypeValues.includes(type);
}

export enum BombActionCategory {
  DEFUSE_BOMB = 'defuseBomb',
  DEFUSE_BOMB_NINJA = 'bombDefusedNinja',
  BOMB_EXPLODES = 'bombExplodes',
  PICK_BOMB = 'bombPick',
  PLACE_BOMB = 'placeBomb',
  BOMB_TICKS = 'bombTicks',
  BOMB_CARRIER_DEAD = 'bombCarrierDead',
}
export const bombActionCategoryValues = Object.values(BombActionCategory);
export function isBombActionCategory(category: any): category is BombActionCategory {
  return bombActionCategoryValues.includes(category);
}
