import { AthleteCard, Team, TeamLogo } from '@cstactics/types';
import { StateCreator } from 'zustand';
import { GameAssetsState } from '../game-assets.store';
import {
  createTeam,
  fireAthlete,
  hireAthlete,
  isAthleteInTeam,
  setBenchAthletes,
  setRosterAthletes,
  updateTeam,
  isAthleteInTeamRoster,
} from './teams.actions';
import { _handleAuthStateChangeForTeams } from './teams.utils';
import { RosterAthletes } from '@/pages/team';

export interface TeamsSlice {
  // states

  /** user team */
  team?: Team;
  benchAthletes?: AthleteCard[];
  rosterAthletes?: RosterAthletes;

  // actions

  /**
   * Check if athleteCard is in team
   * @param athleteCardId
   */
  isAthleteInTeam: (athleteCardId: string) => boolean;

  /**
   * Check if athleteCard is in team roster
   * @param athleteCardId
   */
  isAthleteInTeamRoster: (athleteCardId: string) => boolean;

  /**
   * Hire athlete for current user
   * @param athleteCardId
   * @returns
   */
  hireAthlete: (athleteCardId: string) => Promise<void>;

  /**
   * Fire athlete for current user
   * @param athleteCardId
   * @returns
   */
  fireAthlete: (athleteCardId: string) => Promise<void>;

  /**
   * Create a team for current user
   * @param name
   * @param description
   */
  createTeam: (name: string, tag: string, logo: TeamLogo) => Promise<void>;

  /**
   * Update team of current user
   * @param updates
   */
  updateTeam: (updates: Partial<Team>) => Promise<void>;

  /**
   * Set benchAthletes of current user
   * @param benchAthletes
   */
  setBenchAthletes: (benchAthletes: AthleteCard[]) => void;

  /**
   * Set rosterAthletes of current user
   * @param rosterAthletes
   */
  setRosterAthletes: (rosterAthletes: RosterAthletes) => void;

  // init

  /**
   * !! Don't use this in code: It's called by InitCardsSlice.init !!
   * Initialize teams
   * • listen to auth state
   * • query user team if user is authorized
   * • empty store on logout
   */
  _initTeams: () => void;
}

export const createTeamsSlice: StateCreator<GameAssetsState, [], [], TeamsSlice> = () => ({
  isAthleteInTeam,
  isAthleteInTeamRoster,
  createTeam,
  hireAthlete,
  fireAthlete,
  updateTeam,
  setBenchAthletes,
  setRosterAthletes,
  _initTeams: () => {
    _handleAuthStateChangeForTeams();
  },
});
