import { CsMap, isCsMap } from '../../../athlete';
import { LocationDefinition } from '../map/map';
import { MatchAthleteUuid, MatchAthlete } from '../matchSim';
import { RoundRole, isRoundRole } from '../tactic/tactic';
import { BombActionLog } from './bombActionLog';
import { EncounterLog } from './encounter/encounterLog';
import { MoveLog } from './movementLog';

export type TimeLog = MoveLog | EncounterLog | BombActionLog;
export interface TimeLogBase {
  gameTime: number;
  logType: LogType;
}
export function isTimeLogBase(log: any): log is TimeLogBase {
  return !isNaN(log.gameTime) && isLogType(log.logType);
}

export enum LogType {
  MOVE = 'move',
  ENCOUNTER = 'encounter',
  BOMB_ACTION = 'bombAction',
}
export const logTypeValues = Object.values(LogType);
export function isLogType(type: any): type is LogType {
  return logTypeValues.includes(type);
}

export interface StreakCount {
  teamId?: string;
  amount?: number;
}

/**
 * location, [athl.uuid]
 */
export type PositionsLog = Record<LocationDefinition['name'], Partial<Record<RoundRole, MatchAthlete['uuid'][]>>>;
export function isPositionsLog(log: any): log is PositionsLog {
  return (
    Object.keys(log).every((logKey) => typeof logKey === 'string') &&
    Object.entries(log).every(([logKey, logValue]) =>
      Object.entries(log[logKey]).every(
        ([roundRole, athlIdArray]) => isRoundRole(roundRole) && Array.isArray(athlIdArray)
      )
    )
  );
}

export interface MatchVeto {
  map: CsMap;
  counter: number;
  userId: string;
  teamId: string;
}
export function isMatchVeto(veto: any): veto is MatchVeto {
  return (
    isCsMap(veto.map) &&
    typeof veto.counter === 'number' &&
    [veto.userId, veto.teamId].every((foo) => typeof foo === 'string')
  );
}

export type VetoLog = MatchVeto[];
export function isVetoLog(log: any): log is VetoLog {
  return Array.isArray(log) && log.every((entry) => isMatchVeto(entry));
}

export interface AthleteMatchStats extends KDAD {
  athleteId: string;
  renownChange: number;
  enduranceChange: number;
  chemistryChange: ChemistryChange[];
  averageRoundDamage: number;
  isMvp?: boolean;
}
export function isAthleteMatchStats(statChanges: any): statChanges is AthleteMatchStats {
  return (
    typeof statChanges.athleteId === 'string' &&
    [statChanges.renownChange, statChanges.motivationChange, statChanges.averageRoundDamage].every(
      (value) => typeof value === 'number'
    ) &&
    Array.isArray(statChanges.chemistryChange) &&
    statChanges.chemistryChange.every((change: any) => isChemistryChange(change)) &&
    isKDAD(statChanges)
  );
}

export interface KDAD {
  kills: number;
  death: number;
  assists: number;
  damageGiven: number;
}
export function isKDAD(statChanges: any): statChanges is KDAD {
  return [statChanges.kills, statChanges.death, statChanges.assists, statChanges.damageGiven].every(
    (stat) => typeof stat === 'number'
  );
}

export interface TeamStats extends KDAD {
  teamId: string;
  athleteStats: Record<string, AthleteMatchStats>;
}
export function isTeamStats(stats: any): stats is TeamStats {
  return (
    typeof stats.teamId === 'string' &&
    Object.entries(stats.athleteStats).every(
      ([uuid, _stats]) => typeof uuid === 'string' && isAthleteMatchStats(_stats)
    ) &&
    isKDAD(stats)
  );
}

export interface ChemistryChangeLog {
  chemistryWith: MatchAthleteUuid;
  change: number;
}
export function isChemistryChangeLog(log: any): log is ChemistryChangeLog {
  return typeof log.chemistryWith === 'string' && typeof log.change === 'number';
}
export interface ChemistryChange extends ChemistryChangeLog {
  athleteId: MatchAthleteUuid;
}
export function isChemistryChange(change: any): change is ChemistryChange {
  return typeof change.athleteId === 'string' && isChemistryChangeLog(change);
}
