import { StaffCardShort } from '../../csgo-manager';
import { AnalystType } from './analyst/analyst';
import { CoachType } from './coach/coach';
import { FacilityManagerType } from './facilityManager/facilityManager';
import { FitnessTrainerType } from './fitnessTrainer/fitnessTrainer';
import { NutritionistType } from './nutritionist/nutritionist';
import { PrManagerType } from './prManager/prManager';
import { PsychologistType } from './psychologist/psychologist';

import { Gene, isGene } from '../../csgo-manager/athlete/genes';
import { Rarity } from '../../csgo-manager/rack';
import { Gender, VisualGroups } from '../../csgo-manager/athlete/superficials';

export enum StaffType {
  COACH = 'coach',
  FITNESS_TRAINER = 'fitnessTrainer',
  ANALYST = 'analyst',
  FACILITY_MANAGER = 'facilityManager',
  PR_MANAGER = 'prManager',
  SPORT_PSYCHOLOGIST = 'sportPsychologist',
  NUTRITIONIST = 'nutritionist',
}

export type StaffArchetype =
  | AnalystType
  | CoachType
  | FacilityManagerType
  | FitnessTrainerType
  | NutritionistType
  | PrManagerType
  | PsychologistType;

export const staffArchetypeValues = [
  ...Object.values(AnalystType),
  ...Object.values(CoachType),
  ...Object.values(FacilityManagerType),
  ...Object.values(FitnessTrainerType),
  ...Object.values(NutritionistType),
  ...Object.values(PrManagerType),
  ...Object.values(PsychologistType),
];

export enum StaffSlotType {
  NON_SPECIALIZED = 'nonSpecialized',
  COACH = 'coach',
  ANALYST = 'analyst',
}

export const staffTypeValues = Object.values(StaffType);

export function isStaffType(type: string): type is StaffType {
  return staffTypeValues.includes(type as any);
}

// STAFF SLOTS

export interface StaffSlotCategoryDB {
  type: StaffSlotType;
  amount: number;
  assignedStaffs: StaffCardShort[];
}

export type StaffSlotsDB = Record<StaffSlotType, StaffSlotCategoryDB>;

export interface StaffSlotCategory {
  type: StaffSlotType;
  amount: number;
  assignedStaffs: StaffCardShort[];
}

export type StaffSlots = Record<StaffSlotType, StaffSlotCategory>;

export interface LostStaffSlotsCategory {
  type: StaffSlotType;
  amount: number;
  staffsToFireIds: string[];
}

export interface StaffOverrides {
  type?: StaffType;
}

export enum StaffAppearanceSlot {
  BACKGROUND = 'Background',
  EYES = 'Eyes',
  EYEWEAR = 'Eyewear',
  FACE = 'Face',
  HAIR = 'Hair',
  TORSO = 'Torso',
}

export const staffAppearanceSlots = Object.values(StaffAppearanceSlot);
export function isStaffAppearanceSlots(
  staffAppearanceSlot: StaffAppearanceSlot
): staffAppearanceSlot is StaffAppearanceSlot {
  return Object.values(StaffAppearanceSlot).includes(staffAppearanceSlot);
}

export type StaffAppearance = Record<StaffAppearanceSlot, { identifier: string; gene: Gene; blender_ref: string }>;
export function isStaffAppearance(appearance: any): appearance is StaffAppearance {
  if (
    staffAppearanceSlots.every((slot) => {
      return (
        typeof appearance[slot].blender_ref === 'string' &&
        typeof appearance[slot].gene === 'string' &&
        appearance[slot].identifier !== ''
      );
    })
  )
    return true;
  else return false;
}

export interface StaffAppearanceDef {
  identifier: string;
  gene: Gene;
  blender_ref: string;
  rarity: (Rarity | 'ALL')[];
  gender: Gender[];
  visualGroups: (VisualGroups | 'ALL')[];
  weight: number;
  staffTypes: (StaffType | 'ALL')[];
}
