import { MapLocation } from '../../map/map';
import { MatchAthleteUuid, RoundTeam, MatchRoundAthlete } from '../../matchSim';
import { LogType, TimeLogBase } from '../log';
import { ApproachLogEntry, isApproachLogEntry } from './approach';

export interface MatchKill {
  athleteId: MatchAthleteUuid;
  assistedBy: MatchAthleteUuid[];
}
export function isMatchKill(kill: any): kill is MatchKill {
  return typeof kill.athleteId === 'string' && kill.assistedBy.every((assistant: any) => typeof assistant === 'string');
}

export type Encounters = Array<MapLocation>;

export interface EncounterLog extends TimeLogBase {
  logType: LogType.ENCOUNTER;
  location: MapLocation;
  approaches: Record<number, ApproachLogEntry[]>;
  participants: Record<RoundTeam['uuid'], MatchAthleteUuid[]>;
  killedInEncounter: Record<RoundTeam['uuid'], MatchAthleteUuid[]>;
  damagesTaken: Record<RoundTeam['uuid'], { athleteId: MatchAthleteUuid; damage: number }[]>;
  pathingPenalties: Record<RoundTeam[`uuid`], { athleteId: MatchAthleteUuid; penalty: number }[]>;
  /**teamId, athleteId[] */
}
export function isEncounterLog(log: any): log is EncounterLog {
  return log.logType === LogType.ENCOUNTER;
}
