import { RoundPhase } from '../round/round';

export enum TimeConsumingActionType {
  MOVE = 'move',
  ENCOUNTER = 'encounter',
}

export type ActionTimeCost = Record<TimeConsumingActionType, number>;

export function isActionTimeCost(obj: any): obj is ActionTimeCost {
  return typeof obj.move === 'number' && typeof obj.encounter === 'number';
}

export type MatchRoundDuration = Record<RoundPhase, number>;

export function isMatchRoundDuration(obj: any): obj is MatchRoundDuration {
  return typeof obj.preBomb === 'number' && typeof obj.postBomb === 'number';
}
