import { CsMap, isCsMap } from '../../../athlete';
import { GameLogEventBase } from './events';

export interface GameLogTeamEventBase extends GameLogEventBase {
  teamId: string;
  actionType: GameLogTeamEventType;
}

export interface GameLogTeamEventMapBan extends GameLogTeamEventBase {
  gameTime: number;
  mapName: CsMap;
  banned: boolean;
  actionType: GameLogTeamEventType.MAP_BAN;
}
export function isGameLogTeamEventMapBan(action: any): action is GameLogTeamEventMapBan {
  return (
    action.actionType === GameLogTeamEventType.MAP_BAN && isCsMap(action.mapName) && typeof action.banned === 'boolean'
  );
}

// export interface GameLogTeamEventRoundResult extends GameLogTeamEventBase {
//   winReason: WinReason;
//   actionType: GameLogTeamEventType.ROUND_WIN;
//   teamStats: TeamStats;
// }
// export function isTeamRoundResultAction(action: any): action is GameLogTeamEventRoundResult {
//   return action.actionType === GameLogTeamEventType.ROUND_WIN;
// }

// export interface GameLogTeamEventEncounter extends Omit<GameLogTeamEventBase, 'teamId'> {
//   actionType: GameLogTeamEventType.ENCOUNTER;
//   participants: Record<Team['uuid'], MatchAthleteUuid[]>;
//   gameTime: number;
//   location: string;
// }
// export function isGameLogTeamEventEncounter(event: GameLogTeamEvent): event is GameLogTeamEventEncounter {
//   return event.actionType === GameLogTeamEventType.ENCOUNTER;
// }

// export interface GameLogTeamEventTeamStats extends GameLogTeamEventBase {
//   actionType: GameLogTeamEventType.TEAM_STATS;
//   stats: Record<string, TeamStats>;
// }

export enum GameLogTeamEventType {
  MAP_BAN = 'mapBan',
  // ROUND_WIN = 'roundWin',
  // ENCOUNTER = 'encounter',
  // TEAM_STATS = 'teamStats',
}
export const gameLogTeamEventTypeValues = Object.values(GameLogTeamEventType);
export function isGameLogTeamEventType(type: any): type is GameLogTeamEventType {
  return gameLogTeamEventTypeValues.includes(type);
}

export type GameLogTeamEvent = GameLogTeamEventMapBan; // | GameLogTeamEventRoundResult | GameLogTeamEventEncounter;
