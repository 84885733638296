import { MapLocation } from '../map/map';
import { TimeLogBase, LogType, TimeLog, isTimeLogBase } from './log';

export enum MoveType {
  QUICK_MOVE = 'quick',
  STANDARD_MOVE = 'standard',
  SPAWN = 'spawn',
}
export const moveTypeValues = Object.values(MoveType);
export function isMoveType(val: any): val is MoveType {
  return moveTypeValues.includes(val);
}

export interface MoveLog extends TimeLogBase {
  logType: LogType.MOVE;
  moveType: MoveType;
  athleteId: string;
  teamId: string;
  newLocation: MapLocation;
  oldLocation: MapLocation;
}
export function isMoveLog(log: TimeLog): log is MoveLog {
  return (
    isTimeLogBase(log) &&
    log.logType === LogType.MOVE &&
    isMoveType(log.moveType) &&
    typeof log.athleteId === 'string' &&
    typeof log.teamId === 'string' &&
    typeof log.newLocation === 'string' &&
    typeof log.oldLocation === 'string'
  );
}
