import { StaffAppearance, StaffArchetype, StaffType } from '../../../facility/staff/staff';
import { CountryNames, Gender, NamingGroups, VisualGroups } from '../../athlete';
import { TeamStatus } from '../../../buff-system';
import { Rarity } from '../../rack';
import { Card, CardShort, CardType } from '../card';
import { Employable } from '../employable';

export interface StaffCard extends Card, Employable {
  type: CardType.STAFF;
  content: Staff;
}

export interface Staff {
  // staff related
  rarity: Rarity;
  type: StaffType;
  skillRating: number;
  talentRating: number;
  archetype: StaffArchetype;
  matchesLeft: number;
  salaryMultiplier: number;
  teamStatuses: TeamStatus[];
  statusModifiers: StaffTeamStatusModifier[];

  // superficial stuff
  country: CountryNames;
  visualGroup: VisualGroups;
  namingGroup: { firstName: NamingGroups; lastName: NamingGroups };
  gender: Gender;
  firstName: string;
  lastName: string;
  nickName: string;
  appearance: StaffAppearance;
}

export function isStaffCard(card: Card): card is StaffCard {
  return card.type === CardType.STAFF;
}

export interface StaffTeamStatusModifier {
  uuid: string;
  multiplier: number;
}

export interface StaffCardShort extends CardShort, Employable {
  type: CardType.STAFF;
  content: StaffShort;
}

export type StaffShort = Pick<
  Staff,
  'rarity' | 'country' | 'firstName' | 'lastName' | 'nickName' | 'gender' | 'type' | 'skillRating' | 'talentRating'
>;

// STAFF TEMPLATE

/**
 * This is used while staff generation
 * Determines what type of Statuses staff can have. Staff templates are defined in a big list of possibilities.
 */
export type StaffTemplate = Pick<
  Staff,
  'type' | 'rarity' | 'archetype' | 'salaryMultiplier' | 'teamStatuses' | 'statusModifiers'
>;
