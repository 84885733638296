import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { FC } from 'react';
import { useStyles } from '@/lib/styles/styles.hooks';
import { SplashScreenStyles } from './SplashScreen.style';
import Image from 'next/image';

interface SplashScreenProps {
  /** styles */
  sx?: SxProps<Theme>;
}

const SplashScreen: FC<SplashScreenProps> = ({ sx }) => {
  const styles = useStyles(SplashScreenStyles, {}, sx);

  return (
    <Box className="SplashScreen-root" sx={styles.root}>
      <Box className="SplashScreen-logo" sx={styles.logo}>
        <Image alt={'logo'} src={'/logo.png'} fill />
      </Box>
      <Box className="SplashScreen-loading" sx={styles.loading}></Box>
    </Box>
  );
};

export default SplashScreen;
