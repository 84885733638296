import { Team } from '../../../team';
import { MatchAthleteUuid } from '../matchSim';
import { RoundPhase, WinReason, isWinReason } from '../round/round';
import { RoundRole } from '../tactic/tactic';
import { TimeLog, StreakCount, KDAD, isKDAD, isTimeLogBase } from './log';

export interface RoundDuration {
  [RoundPhase.PRE_BOMB]: number;
  [RoundPhase.POST_BOMB]: number;
  total: number;
}

export interface RoundResultLog {
  duration: RoundDuration;
  timeLogs: TimeLog[];
  winner: Team['uuid'];
  whoIs: Record<Team['uuid'], RoundRole>;
  winReason: WinReason;
  roundCount: number;
  streakCount: StreakCount;
  roundStats: Record<Team['uuid'], Record<MatchAthleteUuid, KDAD>>;
}
export function isRoundResultLog(log: any): log is RoundResultLog {
  return (
    typeof log.win === 'string' &&
    isWinReason(log.winReason) &&
    typeof log.roundCount === 'number' &&
    Object.values(log.statChanges).every((stats) => isKDAD(stats)) &&
    log.timeLogs.every((log: any) => isTimeLogBase(log))
  );
}
