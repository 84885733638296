/**
 * Colors are defined in Figma File:
 * https://www.figma.com/file/ioN7hf7dC9sLkoSICFA4Yt/CS-Tactics-Design--styleguide?node-id=53%3A2
 */

import { createTheme, PaletteOptions } from '@mui/material';

// used in theme
export const paletteOptions: PaletteOptions = {
  primary: {
    main: '#0f172a',
    transparent: 'rgba(15, 23, 42, 0.2)',
  },
  secondary: {
    main: '#f8fafc',
    transparent: 'rgba(248, 250, 252, 0.2)',
  },
  core: {
    light: '#BEF264',
    main: '#A3E635',
    dark: '#84CC16',
    transparent: 'rgba(163, 230, 53, 0.2)',
  },
  accent: {
    light: '#22d3ee',
    main: '#22d3ee',
    dark: '#22d3ee',
    transparent: 'rgba(34, 211, 238, 0.2)',
  },
  error: {
    light: '#FECACA',
    main: '#f87171',
    dark: '#dc2626',
    transparent: 'rgba(248, 113, 113, 0.2)',
  },
  warning: {
    light: '#fef08a',
    main: '#facc15',
    dark: '#ca8a04',
    transparent: 'rgba(250, 204, 21, 0.2)',
  },
  info: {
    light: '#bfdbfe',
    main: '#60a5fa',
    dark: '#2563eb',
    transparent: 'rgba(96, 165, 250, 0.2)',
  },
  success: {
    light: '#bbf7d0',
    main: '#4ade80',
    dark: '#16a34a',
    transparent: 'rgba(74, 222, 128, 0.2)',
  },

  // Core
  bgPrimary: {
    primary: '#0F172A',
    secondary: '#1E293B',
    tertiary: '#334155',
  },
  bgSecondary: {
    primary: '#F8FAFC',
    secondary: '#E2E8F0',
    tertiary: '#CBD5E1',
  },
  contentPrimary: {
    primary: '#F8FAFC',
    secondary: '#E2E8F0',
    tertiary: '#CBD5E1',
    disabled: '#64748B',
  },
  contentSecondary: {
    primary: '#0F172A',
    secondary: '#334155',
    tertiary: '#475569',
    disabled: '#94A3B8',
  },

  // Game Sides

  counterTerrorist: {
    main: '#6366F1',
    light: '#A5B4FC',
    dark: '#4338CA',
    transparent: 'rgba(99, 102, 241, 0.2)',
  },
  terrorist: {
    main: '#F59E0B',
    light: '#FCD34D',
    dark: '#B45309',
    transparent: 'rgba(245, 158, 11, 0.2)',
  },

  // Rarities

  rarityLegendary: {
    main: '#F59E0B',
    dark: '#B45309',
    light: '#FCD34D',
    transparent: 'rgba(245, 158, 11, 0.2)',
  },
  rarityEpic: {
    main: '#F43F5E',
    dark: '#BE123C',
    light: '#FDA4AF',
    transparent: 'rgba(244, 63, 94, 0.2)',
  },
  rarityRare: {
    main: '#A855F7',
    dark: '#7E22CE',
    light: '#D8B4FE',
    transparent: 'rgba(168, 85, 247, 0.2)',
  },
  rarityUncommon: {
    main: '#3B82F6',
    dark: '#1D4ED8',
    light: '#93C5FD',
    transparent: 'rgba(59, 130, 246, 0.2)',
  },
  rarityCommon: {
    main: '#737373',
    dark: '#404040',
    light: '#D4D4D4',
    transparent: 'rgba(115, 115, 115, 0.2)',
  },

  // Theme Colors

  rose: {
    main: '#FB7185',
    dark: '#E11D48',
    light: '#FECDD3',
    transparent: '',
  },
  pink: {
    main: '#F472B6',
    dark: '#DB2777',
    light: '#FBCFE8',
    transparent: '',
  },
  fuchsia: {
    main: '#E879F9',
    dark: '#C026D3',
    light: '#F5D0FE',
    transparent: '',
  },
  violet: {
    main: '#C084FC',
    dark: '#7C3AED',
    light: '#E9D5FF',
    transparent: '',
  },
  sky: {
    main: '#38BDF8',
    dark: '#0284C7',
    light: '#BAE6FD',
    transparent: '',
  },
  teal: {
    main: '#2DD4BF',
    dark: '#0D9488',
    light: '#99F6E4',
    transparent: '',
  },
  orange: {
    main: '#FB923C',
    dark: '#EA580C',
    light: '#FED7AA',
    transparent: '',
  },
  zinc: {
    main: '#52525B',
    dark: '#27272A',
    light: '#A1A1AA',
    transparent: '',
  },
  gray: {
    main: '#E5E7EB',
    dark: '#9CA3AF',
    light: '#F9FAFB',
    transparent: '',
  },

  emerald: {
    main: '#34d399',
    dark: '#059669',
    light: '#a7f3d0',
    transparent: 'rgba(52, 211, 153, 0.2)',
  },
  cyan: {
    main: '#22d3ee',
    dark: '#0891b2',
    light: '#a5f3fc',
    transparent: 'rgba(34, 211, 238, 0.2)',
  },

  // Game

  worldChampionsLeague: {
    main: 'rgba(217, 70, 239, 1)',
    dark: 'rgba(162, 28, 175, 1)',
    light: 'rgba(240, 171, 252, 1)',
    transparent: 'rgba(217, 70, 239, 0.2)',
    gradient: 'linear-gradient(180deg, rgba(217, 70, 239, 0.4) 0%, rgba(217, 70, 239, 0) 100%)',
  },
  masterLeague: {
    main: 'rgba(217, 70, 239, 1)',
    dark: 'rgba(162, 28, 175, 1)',
    light: 'rgba(240, 171, 252, 1)',
    transparent: 'rgba(217, 70, 239, 0.2)',
    gradient: 'linear-gradient(180deg, rgba(217, 70, 239, 0.4) 0%, rgba(217, 70, 239, 0) 100%)',
  },
  diamondLeague: {
    main: 'rgba(217, 70, 239, 1)',
    dark: 'rgba(162, 28, 175, 1)',
    light: 'rgba(240, 171, 252, 1)',
    transparent: 'rgba(217, 70, 239, 0.2)',
    gradient: 'linear-gradient(180deg, rgba(217, 70, 239, 0.4) 0%, rgba(217, 70, 239, 0) 100%)',
  },
  platinumLeague: {
    main: 'rgba(217, 70, 239, 1)',
    dark: 'rgba(162, 28, 175, 1)',
    light: 'rgba(240, 171, 252, 1)',
    transparent: 'rgba(217, 70, 239, 0.2)',
    gradient: 'linear-gradient(180deg, rgba(217, 70, 239, 0.4) 0%, rgba(217, 70, 239, 0) 100%)',
  },
  goldLeague: {
    main: 'rgba(253, 224, 71, 1)',
    dark: 'rgba(234, 179, 8, 1)',
    light: 'rgba(254, 249, 195, 1)',
    transparent: 'rgba(253, 224, 71, 0.2)',
    gradient: 'linear-gradient(180deg, rgba(253, 224, 71, 0.4) 0%, rgba(253, 224, 71, 0) 100%)',
  },
  silverLeague: {
    main: 'rgba(107, 114, 128, 1)',
    dark: 'rgba(55, 65, 81, 1)',
    light: 'rgba(209, 213, 219, 1)',
    transparent: 'rgba(107, 114, 128, 0.2)',
    gradient: 'linear-gradient(180deg, rgba(107, 114, 128, 0.4) 0%, rgba(107, 114, 128, 0) 100%)',
  },
  woodLeague: {
    main: 'rgba(217, 119, 6, 1)',
    dark: 'rgba(146, 64, 14, 1)',
    light: 'rgba(251, 191, 36, 1)',
    transparent: 'rgba(217, 119, 6, 0.2)',
    gradient: 'linear-gradient(180deg, rgba(217, 119, 6, 0.4) 0%, rgba(217, 119, 6, 0) 100%)',
  },
  goldWinner: {
    main: 'rgba(253, 224, 71, 1)',
    dark: 'rgba(234, 179, 8, 1)',
    light: 'rgba(254, 249, 195, 1)',
    transparent: 'rgba(253, 224, 71, 0.2)',
    gradient: 'linear-gradient(180deg, #FDE047 0%, #EAB308 100%)',
  },
  silverWinner: {
    main: 'rgba(107, 114, 128, 1)',
    dark: 'rgba(55, 65, 81, 1)',
    light: 'rgba(209, 213, 219, 1)',
    transparent: 'rgba(107, 114, 128, 0.2)',
    gradient: 'linear-gradient(180deg, #6B7280 0%, #374151 100%)',
  },
  bronzeWinner: {
    main: 'rgba(217, 119, 6, 1)',
    dark: 'rgba(146, 64, 14, 1)',
    light: 'rgba(251, 191, 36, 1)',
    transparent: 'rgba(217, 119, 6, 0.2)',
    gradient: 'linear-gradient(180deg, #D97706 0%, #92400E 100%)',
  },

  // Currencies

  premiumCurrency: {
    main: '#818CF8',
    dark: '#4F46E5',
    light: '#C7D2FE',
    transparent: 'rgba(129, 140, 248, 0.2)',
    gradient: 'linear-gradient(180deg, #818CF8 0%, #4F46E5 100%)',
  },
  softCurrency: {
    main: '#E5E7EB',
    dark: '#9CA3AF',
    light: '#F9FAFB',
    transparent: 'rgba(229, 231, 235, 0.2)',
    gradient: 'linear-gradient(180deg, #E5E7EB 0%, #9CA3AF 100%)',
  },

  // AI Team Types

  rookie: {
    main: '#0E7490',
    dark: '#164E63',
    light: '#06B6D4',
    transparent: 'rgba(red(#0E7490), green(#0E7490), blue(#0E7490), 0.2)',
    gradient: 'linear-gradient(180deg, #0E7490 0%, #164E63 100%)',
  },
  noob: {
    main: '#1D4ED8',
    dark: '#1E3A8A',
    light: '#3B82F6',
    transparent: 'rgba(red(#1E3A8A), green(#1E3A8A), blue(#1E3A8A), 0.2)',
    gradient: 'linear-gradient(180deg, #1D4ED8 0%, #1E3A8A 100%)',
  },
  amateur: {
    main: '#15803D',
    dark: '#14532D',
    light: '#22C55E',
    transparent: 'rgba(red(#14532D), green(#14532D), blue(#14532D), 0.2)',
    gradient: 'linear-gradient(180deg, #15803D 0%, #14532D 100%)',
  },
  localPro: {
    main: '#A16207',
    dark: '#713F12',
    light: '#EAB308',
    transparent: 'rgba(red(#713F12), green(#713F12), blue(#713F12), 0.2)',
    gradient: 'linear-gradient(180deg, #A16207 0%, #713F12 100%)',
  },
  pro: {
    main: '#C2410C',
    dark: '#7C2D12',
    light: '#F97316',
    transparent: 'rgba(red(#7C2D12), green(#7C2D12), blue(#7C2D12), 0.2)',
    gradient: 'linear-gradient(180deg, #C2410C 0%, #7C2D12 100%)',
  },
  risingStar: {
    main: '#B91C1C',
    dark: '#7F1D1D',
    light: '#EF4444',
    transparent: 'rgba(red(#7F1D1D), green(#7F1D1D), blue(#7F1D1D), 0.2)',
    gradient: 'linear-gradient(180deg, #B91C1C 0%, #7F1D1D 100%)',
  },
  superStar: {
    main: '#6D28D9',
    dark: '#4C1D95',
    light: '#8B5CF6',
    transparent: 'rgba(red(#4C1D95), green(#4C1D95), blue(#4C1D95), 0.2)',
    gradient: 'linear-gradient(180deg, #6D28D9 0%, #4C1D95 100%)',
  },
};

// used in theme style overrides
export const { palette } = createTheme({ palette: paletteOptions });
