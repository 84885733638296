import { Dialogue, isDialogue } from '../dialogue/dialogue';

export interface Story {
  name: string;
  eventType: StoryEventType;
  category: StoryCategory;
  weight: number;
  dialogues: Dialogue[];
}
export function isStory(obj: any): obj is Story {
  return (
    isStoryEvent(obj.event) &&
    typeof obj.name === 'string' &&
    isStoryCategory(obj.category) &&
    typeof obj.weight === 'number' &&
    Array.isArray(obj.dialogues) &&
    obj.dialogues.every((dialogue: any) => isDialogue(dialogue))
  );
}

export enum StoryCategory {
  STATS = 'stats',
  TRAITS = 'traits',
  CHEMISTRY = 'chemistry',
  TACTICS = 'tactics',
  APPROACHES = 'approaches',
  MOTIVATION = 'motivation',
  ROOMS = 'rooms',
  SPONSORS = 'sponsors',
}

export const storyCategoryValues = Object.values(StoryCategory);
export function isStoryCategory(obj: any): obj is StoryCategory {
  return storyCategoryValues.includes(obj);
}

// export enum StoryEventBase {
//   // BOMB_PLACEMENT = LogType.BOMB_ACTION_LOG,
//   // ENCOUNTER = LogType.ENCOUNTER_LOG,
//   GAME_END = 'gameEnd',
//   MAP_BAN = 'mapBan',
//   // MOVEMENT = LogType.MOVE_LOG,
//   ROUND_END = 'roundEnd', // also used for faked rounds
// }
// export const StoryEvent = { ...LogType, ...StoryEventBase };
// export type StoryEventType = LogType | StoryEventBase;

export enum StoryEventType {
  BOMB_PLACEMENT = 'bombAction',
  ENCOUNTER = 'encounter',
  GAME_END = 'gameEnd',
  MAP_BAN = 'mapBan',
  MOVEMENT = 'move',
  ROUND_END = 'roundEnd', // also used for faked rounds
  // TEAM_STATUS = 'teamStatus', // NO DATA provided
}

export const storyEventTypeValues = Object.values(StoryEventType);
export function isStoryEvent(obj: any): obj is StoryEventType {
  return storyEventTypeValues.includes(obj);
}
