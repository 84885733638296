import { ComponentStylesWithFns } from '@/lib/styles/styles.types';
import { SystemStyleObject, Theme } from '@mui/system';

const dotStyle: SystemStyleObject<Theme> = {
  borderRadius: '50%',
  width: '2.5em',
  height: '2.5em',
  animationFillMode: 'both',
  animation: 'fadeInOut 1.8s infinite ease-in-out',
};

const styles: ComponentStylesWithFns = {
  root: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    position: 'relative',
    width: '33vw',
    height: '6.9vw',
  },
  loading: {
    'marginTop': 8,
    ...dotStyle,
    'color': 'cyan.main',
    'fontSize': '7px',
    'position': 'relative',
    'textIndent': '-9999em',
    'transform': 'translateZ(0)',
    'animationDelay': '-0.16s',

    '&:before': {
      ...dotStyle,
      content: '""',
      position: 'absolute',
      top: 0,
      left: '-3.5em',
      animationDelay: '-0.32s',
    },
    '&:after': {
      ...dotStyle,
      content: '""',
      position: 'absolute',
      top: 0,
      left: '3.5em',
    },

    '@keyframes fadeInOut': {
      '0%, 80%, 100%': {
        boxShadow: '0 2.5em 0 -1.3em',
      },
      '40%': { boxShadow: '0 2.5em 0 0' },
    },
  },
};

export { styles as SplashScreenStyles };
