// EMPLOYABLE

export interface HiredStatus {
  isHired: true;
  hiredAt: number;
}

export interface NotHiredStatus {
  isHired: false;
  firedAt?: number;
}

export type EmploymentStatus = (HiredStatus | NotHiredStatus) & { salary: number };

export interface Employable {
  employmentStatus: EmploymentStatus;
}

export enum EmploymentStatusType {
  HIRED = 'hired',
  NOT_HIRED = 'not_hired',
  BLOCKED = 'blocked',
}
