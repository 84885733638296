import { AthleteCard, Card, Employable } from '@cstactics/types';
import { StateCreator } from 'zustand';
import { GameAssetsState } from '../game-assets.store';
import { getEmployableBlockedDistance, getAthleteCard, getCard, isEmployableBlocked } from './cards.actions';
import { _handleAuthStateChangeForCards } from './cards.utils';

export interface CardsSlice {
  // states

  /** athlete cards */
  athleteCards?: AthleteCard[];

  // actions

  /** get card by id */
  getCard: (id: string) => Card | undefined;

  // athlete card actions

  /** get athlete card by id */
  getAthleteCard: (id: string) => AthleteCard | undefined;

  /** check if athlete is blocked */
  isEmployableBlocked: (employable: Employable) => boolean;

  /** get distance until athlete can be hired again */
  getEmployableBlockedDistance: (employable: Employable) => string | undefined;

  // init

  /**
   * !! Don't use this in code: It's called by InitCardsSlice.init !!
   * Initialize cards store
   * • listen to auth state
   * • query user cards if user is authorized
   * • empty store in logout
   */
  _initCards: () => void;
}

export const createCardsSlice: StateCreator<GameAssetsState, [], [], CardsSlice> = () => ({
  getCard,
  getAthleteCard,
  isEmployableBlocked,
  getEmployableBlockedDistance,
  _initCards: () => {
    _handleAuthStateChangeForCards();
  },
});
