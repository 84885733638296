import { createTheme } from '@mui/material';
import { breakpoints } from './breakpoints';
import { paletteOptions } from './palette';
import MuiAccordionOverrides from './stylesOverrides/MuiAccordion';
import MuiAvatarOverrides from './stylesOverrides/MuiAvatar';
import MuiButtonOverrides from './stylesOverrides/MuiButton';
import MuiInputLabelOverrides from './stylesOverrides/MuiInputLabel';
import MuiPaginationOverrides from './stylesOverrides/MuiPagination';
import MuiPaperOverrides from './stylesOverrides/MuiPaper';
import MuiSelectOverrides from './stylesOverrides/MuiSelect';
import MuiSvgButtonOverrides from './stylesOverrides/MuiSvgIcon';
import MuiTextFieldOverrides from './stylesOverrides/MuiTextField';
import { setTypographyVariants, typography } from './typography';
import MuiTabsOverrides from './stylesOverrides/MuiTabs';

const theme = createTheme({
  components: {
    ...MuiAccordionOverrides,
    ...MuiAvatarOverrides,
    ...MuiButtonOverrides,
    ...MuiInputLabelOverrides,
    ...MuiPaginationOverrides,
    ...MuiPaperOverrides,
    ...MuiSelectOverrides,
    ...MuiSvgButtonOverrides,
    ...MuiTabsOverrides,
    ...MuiTextFieldOverrides,
  },
  palette: paletteOptions,
  shape: { borderRadius: 4 },
  spacing: [0, 4, 8, 16, 24, 32, 48, 64, 72, 112],
  breakpoints,
  typography,
});

setTypographyVariants(theme);

export { theme };
