import { AthleteCard, Card, Employable, EmploymentStatusType } from '@cstactics/types';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import { useGameAssetsStore } from '../game-assets.store';
import { NotHiredStatus } from '@cstactics/types/src';

// Getter

export const getCard = (id: string): Card | undefined => {
  return getAthleteCard(id);
};

// Athlete Card

export const getAthleteCard = (id: string): AthleteCard | undefined => {
  const cards = useGameAssetsStore.getState().athleteCards;
  return cards?.find((card) => card.uuid === id);
};

export const isEmployableBlocked = (employable: Employable): boolean => {
  const latestFiredAt = !employable.employmentStatus.isHired
    ? (employable.employmentStatus as NotHiredStatus).firedAt
    : undefined;
  if (!latestFiredAt) return false;

  return latestFiredAt + 1.728e8 > new Date().getTime();
};

export const getEmployableBlockedDistance = (employable: Employable): string | undefined => {
  const latestFiredAt = !employable.employmentStatus.isHired
    ? (employable.employmentStatus as NotHiredStatus).firedAt
    : undefined;
  if (!latestFiredAt) return;

  return latestFiredAt + 1.728e8 > new Date().getTime()
    ? formatDistanceToNowStrict(latestFiredAt + 1.728e8)
    : undefined;
};

export const getEmployableState = (employable: Employable): EmploymentStatusType => {
  if (isEmployableBlocked(employable)) return EmploymentStatusType.BLOCKED;
  if (employable.employmentStatus.isHired) return EmploymentStatusType.HIRED;
  return EmploymentStatusType.NOT_HIRED;
};
