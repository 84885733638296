import { Filter, isFilter } from '../filters/filter';

export interface Dialogue {
  name: string;
  caster: CasterRole | 'any';
  message: DialogueContent;
  timingShift: number;
  filters: Filter[];
}

export function isDialogue(obj: any): obj is Dialogue {
  return (
    typeof obj.name === 'string' &&
    isCasterRole(obj.caster) &&
    typeof obj.content === 'string' &&
    typeof obj.timeShift === 'number' &&
    Array.isArray(obj.filters) &&
    obj.filters.every((filter: any) => isFilter(filter))
  );
}

export enum CasterRole {
  CASTER_A = 'casterA',
  CASTER_B = 'casterB',
}

export const casterRoleValues = Object.values(CasterRole);
export function isCasterRole(obj: any): obj is CasterRole {
  return casterRoleValues.includes(obj);
}

export type DialogueContent = string;
