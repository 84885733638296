import { LockInput } from '@/components/others/LockInput/LockInput';
import { StoreProvider } from '@/lib/providers/store.provider';
import MixpanelAPI from '@/lib/mixpanel/mixpanel.api';
import '@/lib/styles/globals.css';
import { theme } from '@/theme/theme';
import { ThemeProvider } from '@mui/material';

import mixpanel from 'mixpanel-browser';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { SWRConfig } from 'swr';
import { withPasswordProtect } from 'next-password-protect';
import SplashScreen from '@/components/blocks/SplashScreen/SplashScreen';
import { AppRoute } from '@/_routes';

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const router = useRouter();

  const [isSplashScreenShown, showSplashScreen] = useState<boolean>(true);

  /**
   * Initialize Tag Manager and Mixpanel
   */
  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM || '',
    });
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_ID || '', {
      debug: true,
      ignore_dnt: true,
      track_pageview: false, // disabled and done in useEffect due to SPA
    });
  }, []);

  useEffect(() => {
    // console.log(`%c [App] initial route:: ${router.pathname} `, 'background: #222; color: #bada55');
    MixpanelAPI.pageView();

    const showSplashOnRoute = [AppRoute.LANDING, AppRoute.DASHBOARD].includes(router.pathname as AppRoute);

    const timeout = setTimeout(
      () => {
        showSplashScreen(false);
      },
      showSplashOnRoute ? 3500 : 1000
    );

    const handleRouteChange = (url: string) => {
      // console.log(
      //   `%c [App] route change:: ${router.pathname} -> ${url} at ${new Date().toDateString()}`,
      //   'background: #222; color: #bada55'
      // );

      if (url === '/dashboard' && [AppRoute.LANDING, AppRoute.LOGIN].includes(router.pathname as AppRoute)) {
        clearTimeout(timeout);
        showSplashScreen(true);
      }
    };

    const handleRouteChangeComplete = () => {
      // console.log(
      //   `%c [App] route change complete:: ${url} ${new Date().toDateString()}`,
      //   'background: #222; color: #bada55'
      // );

      showSplashScreen(false);

      MixpanelAPI.pageView();
    };

    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router.events]);

  return (
    <SessionProvider session={session} refetchInterval={5 * 60}>
      <ThemeProvider theme={theme}>
        <StoreProvider>
          <Head>
            <title>CounterStrike Tactics</title>
            <meta name="description" content="Manager Game for CounterStrike" />
            <link rel="icon" href="/favicon.ico" />
          </Head>
          <NextNProgress options={{ showSpinner: false }} />
          <SWRConfig
            value={{
              refreshInterval: 0,
              revalidateOnFocus: false,
              revalidateOnReconnect: false,

              fetcher: (resource, init) => fetch(resource, init).then((res) => res.json()),
            }}
          >
            {isSplashScreenShown ? <SplashScreen /> : <Component {...pageProps} />}
          </SWRConfig>
        </StoreProvider>
      </ThemeProvider>
    </SessionProvider>
  );
}

export default process.env.PASSWORD_PROTECT
  ? withPasswordProtect(MyApp, {
      loginApiUrl: '/api/unlock',
      checkApiUrl: '/api/unlock-password-check',
      loginComponent: LockInput,
      loginComponentProps: {
        buttonBackgroundColor: '#FFF',
        buttonColor: '#000',
        logo: '/logo.png',
      },
    })
  : MyApp;
