import { MediaItem } from '../../utils';
import { Card, CardType } from '../card';

export enum SlotType {
  CARD = 'CARD',
}

export interface Slot {
  slotId: string;
  packId: string;
  name: string;
  type: SlotType;
  content: Card;
  rendering?: any;
  image?: MediaItem;
}

export interface SlotRenderInfo {
  slotId: string;
  cardType: CardType;
  renderParams: string;
}
