import { Components } from '@mui/material';
import { palette } from '../palette';

const MuiButtonOverrides: Components = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        transition: 'all ease .3s',
        svg: {
          minWidth: 20,
          minHeight: 20,
        },
      },
      sizeMedium: {
        height: 52,
        minWidth: 52,
        padding: '14px 16px',

        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 500,
      },
    },
    variants: [
      {
        props: { size: 'medium' },
        style: {},
      },
      {
        props: { variant: 'contained' },
        style: {
          'backgroundColor': palette.contentPrimary.primary,
          'color': palette.contentSecondary.primary,
          '&:hover': {
            backgroundColor: palette.contentPrimary.tertiary,
            color: palette.contentSecondary.primary,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.contentPrimary.disabled,
            color: palette.contentSecondary.disabled,
          },
        },
      },
      {
        props: { variant: 'contained', color: 'accent' },
        style: {
          'width': '100%',
          'backgroundColor': palette.accent.main,
          'color': palette.contentSecondary.primary,
          '&:hover': {
            backgroundColor: palette.accent.transparent,
            color: palette.contentSecondary.primary,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.contentPrimary.disabled,
            color: palette.contentSecondary.disabled,
          },
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          'backgroundColor': 'transparent',
          'color': palette.contentPrimary.tertiary,
          'borderColor': palette.contentPrimary.tertiary,
          '&:hover': {
            backgroundColor: 'transparent',
            color: palette.contentPrimary.primary,
            borderColor: palette.contentPrimary.primary,
          },
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
            color: palette.contentPrimary.disabled,
          },
        },
      },
      {
        props: { variant: 'text' },
        style: {
          'color': palette.contentPrimary.primary,
          '&:hover': {
            color: palette.accent.main,
            backgroundColor: palette.accent.transparent,
          },
          '&.Mui-disabled': {
            color: palette.contentPrimary.disabled,
          },
        },
      },
      {
        props: { variant: 'tab' },
        style: {
          display: 'flex',
          justifyContent: 'flex-start',
        },
      },
      {
        props: { variant: 'tab', color: 'primary' },
        style: {
          'backgroundColor': palette.contentPrimary.primary,
          'color': palette.contentSecondary.primary,
          '&:hover': {
            backgroundColor: palette.contentPrimary.primary,
            color: palette.contentSecondary.primary,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.contentPrimary.disabled,
            color: palette.contentSecondary.disabled,
          },
        },
      },
      {
        props: { variant: 'tab', color: 'secondary' },
        style: {
          'backgroundColor': 'transparent',
          'color': palette.contentPrimary.primary,
          '&:hover': {
            backgroundColor: palette.secondary.transparent,
            color: palette.contentPrimary.primary,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.contentPrimary.disabled,
            color: palette.contentSecondary.disabled,
          },
        },
      },
      {
        props: { variant: 'borderless' },
        style: {
          'backgroundColor': 'transparent',
          'color': palette.contentPrimary.primary,
          '&.Mui-disabled': {
            color: palette.contentPrimary.disabled,
          },
        },
      },
    ],
  },
  MuiIconButton: {
    defaultProps: {
      color: 'accent',
    },
    styleOverrides: {
      root: {
        height: 52,
        width: 52,
      },
    },
    variants: [
      {
        props: { color: 'accent' },
        style: {
          'color': palette.contentPrimary.primary,
          '&:hover': {
            color: palette.accent.main,
            backgroundColor: palette.accent.transparent,
          },
          '&.Mui-disabled': {
            color: palette.contentPrimary.disabled,
          },
        },
      },
    ],
  },
};

export default MuiButtonOverrides;
