import { ApproachDefinition, isApproachDefinition } from '../../encounter/encounter';
import { MatchAthleteUuid } from '../../matchSim';
import { MatchKill } from './encounterLog';

export interface ApproachDamage {
  athleteId: MatchAthleteUuid;
  damage: number;
}
export function isApproachDamage(damage: any): damage is ApproachDamage {
  return typeof damage.athleteId === 'string' && typeof damage.damage === 'number';
}

export interface ApproachResult {
  success: boolean;
  damageEffects: ApproachDamage[];
  kills: MatchKill[];
}
export function isApproachResult(result: any): result is ApproachResult {
  return typeof result.success === 'boolean' && result.damageEffects.every((effect: any) => isApproachDamage(effect));
}

export interface ApproachLogEntry extends ApproachResult {
  athleteId: MatchAthleteUuid;
  approach: ApproachDefinition;
  initiative: number;
  health: number;
  teamId: string;
  round: number;
  gameTime: number;
}
export function isApproachLogEntry(entry: any): entry is ApproachLogEntry {
  return (
    typeof entry.initiative === 'number' &&
    typeof entry.health === 'number' &&
    typeof entry.teamId === 'string' &&
    isApproachDefinition(entry.approach) &&
    typeof entry.athleteId === 'string'
  );
}
