import {
  Athlete,
  CsRole,
  isAthleteChemistryStats,
  isAthleteSkills,
  isAthleteSystemStatModifiers,
  isCsRole,
  isTalents,
  isTrait,
} from '../../athlete';
import {
  ActiveAthleteEffectValues,
  AppliedBuff,
  AthleteEffect,
  AthleteStatModifierEffect,
  isStatModifierEffect,
} from '../../../buff-system';
import { TeamBaseInfo, TeamRosterExtended, TeamMapPriorities, TeamFame, isTeamMapPriorities } from '../../team';
import { TacticsTagsValue, Path, LocationDefinition, isPath } from './map/map';
import { RoundRole, RoundTactic, TacticsSelection, TeamMapTactics, isTeamMapTactics } from './tactic/tactic';
import { Card, isAthleteCard } from '../../card';
import { isMatchAthleteEffect } from '../../../buff-system/effects/match-athlete-effects';
import { KDAD as KDAD, RoundDuration, TimeLog, isKDAD } from './log';
import { RoundPhase } from './round/round';

export interface MatchLineup extends TeamBaseInfo {
  team: TeamRosterExtended;
  mapPriorities: TeamMapPriorities; // number: 1 .... CsMaps.length with 1 being least desired;
  mapTactics?: Partial<TeamMapTactics>;
  buffs: AppliedBuff[];
  activeEffectValues: ActiveAthleteEffectValues;
}
export function isMatchLineup(lineup: any): lineup is MatchLineup {
  return (
    Object.values(lineup.team).every((athlCard) => isAthleteCard(athlCard as Card)) &&
    isTeamMapPriorities(lineup.mapPriorities) &&
    isTeamMapTactics(lineup.mapTactics) &&
    Array.isArray(lineup.buffs)
  );
}

export type MatchAthleteUuid = MatchAthlete['uuid'];
export type MatchAthlete = Omit<
  Athlete,
  | 'powerDistribution'
  | 'visualGroup'
  | 'namingGroup'
  | 'appearance'
  | 'country'
  | 'firstName'
  | 'lastName'
  | 'matchStats'
  | 'matchHistory'
> & {
  role: CsRole;
  uuid: string;
  teamId: string;
  matchEffects: AthleteStatModifierEffect[];
  motivation: number;
  matchStats: KDAD;
};
export function isMatchAthlete(athl: any): athl is MatchAthlete {
  return (
    // from the Athlete
    isAthleteSkills(athl.skills) &&
    Array.isArray(athl.traits) &&
    athl.traits.every((trait: any) => isTrait(trait)) &&
    isAthleteSystemStatModifiers(athl.systemStatModifiers) &&
    isAthleteChemistryStats(athl.chemistry) &&
    typeof athl.renown === 'number' &&
    // added
    isCsRole(athl.role) &&
    typeof athl.uuid === 'string' &&
    typeof athl.teamId === 'string' &&
    Array.isArray(athl.matchEffects) &&
    athl.matchEffects.every((effect: AthleteEffect) => isStatModifierEffect(effect))
  );
}

export interface MatchRoundAthlete extends MatchAthlete {
  health: number;
  tags: TacticsTagsValue[];
  pathIndex: number;
  stepList: Path;
  location: LocationDefinition['name'];
  roundStats: KDAD;
}
export function isMatchRoundAthlete(athl: any): athl is MatchRoundAthlete {
  return (
    typeof athl.health === 'number' &&
    typeof athl.pathIndex === 'number' &&
    isPath(athl.stepList) &&
    typeof athl.location === 'string' &&
    isKDAD(athl.roundStats)
  );
}

export interface RoundTeam extends MatchTeam {
  athletes: MatchRoundAthlete[];
  roundTactic: RoundTactic;
  stats?: any;
}
export function isRoundTeam(team: MatchTeam): team is RoundTeam {
  return Array.isArray(team.athletes) && team.athletes.every((athl) => isMatchRoundAthlete(athl));
}

export interface TerroristTeam extends RoundTeam {
  bombCarrierIndex: number;
  plantBombChance: number;
}
export function isTerroristTeam(team: RoundTeam): team is TerroristTeam {
  return team.roundRole === RoundRole.TERRORIST;
}

export interface CounterTerroristTeam extends RoundTeam {
  defuseChance: number;
}
export function isCounterTerroristTeam(team: RoundTeam): team is CounterTerroristTeam {
  return team.roundRole === RoundRole.COUNTER_TERRORIST;
}

export interface MatchTeam {
  preferredTactic?: TacticsSelection;
  athletes: MatchAthlete[];
  uuid: string;
  userId: string;
  fame: TeamFame;
  buffs: AppliedBuff[];
  roundRole: RoundRole;
  activeEffectValues: ActiveAthleteEffectValues;
}
export function isMatchTeam(teamBase: any): teamBase is MatchTeam {
  return (
    isTeamMapTactics(teamBase.tacticChoice) &&
    isTeamMapPriorities(teamBase.mapPriorities) &&
    Array.isArray(teamBase.athletes) &&
    teamBase.athletes.every((athl: any) => isMatchAthleteEffect(athl)) &&
    typeof teamBase.uuid === 'string' &&
    typeof teamBase.userId === 'string' &&
    Array.isArray(teamBase.buffs)
  );
}

export interface RoundContext {
  roundTime: RoundDuration;
  timeLogs: TimeLog[];
  roundPhase: RoundPhase;
}

export interface PreBombPhaseContext extends RoundContext {
  roundPhase: RoundPhase.PRE_BOMB;
}
export function isPreBombPhaseContext(context: RoundContext): context is PreBombPhaseContext {
  return context.roundPhase === RoundPhase.PRE_BOMB;
}

export interface PostBombPhaseContext extends RoundContext {
  roundPhase: RoundPhase.POST_BOMB;
  plantedBombLocation: string;
}
export function isPostBombPhaseContext(context: RoundContext): context is PostBombPhaseContext {
  return context.roundPhase === RoundPhase.POST_BOMB;
}
